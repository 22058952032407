import React from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/vertical.css';

const content = [
  {
    title1: 'Elegant',
    title2: 'Staircase Illumination',
    description:
      "Ascend with Graceful Light",
    button: "More Detail",
    link: "/#",
    image: "./img/bg-1.jpg"
  },
  {
    title1: 'Luxurious',
    title2: 'Bedroom Glow',
    description:
      "Transform Your Sleep Space",
    button: "More Detail",
    link: "/#",
    image: "./img/bg-2.jpg"
  },
  {
    title1: 'Modern',
    title2: 'Kitchen Lighting',
    description:
      "Enhance Culinary Creativity",
    button: "More Detail",
    link: "/#",
    image: "./img/bg-3.jpg"
  },
  {
    title1: 'Transform',
    title2: 'Your Bathroom Spaces',
    description:
      "Expert bathroom renovations",
    button: "More Detail",
    link: "/#",
    image: "./img/bg-4.jpg"
  }
];

export default () => (
  <Slider className="slider-wrapper" autoplay={6000} touchDisabled={true} >
            {content.map((item, index) => (
              <div
                key={index}
                className="slider-content"
                style={{ background: `url('${item.image}') no-repeat center center` }}
              >
                <div className="inner">
                  <h1>{item.title1}</h1>
                  <br/>
                  <h1>{item.title2}</h1>
                  <br/>
                  <br/>
                  <br/>
                  <p>{item.description}</p>
                  {/* <br/>
                  <br/>
                  <button onClick={()=> window.open(item.link, "_self")}>
                    <span className="shine"></span>
                    <span>
                      {item.button}
                    </span>
                  </button> */}
                </div>
              </div>
            ))}
        </Slider>
);
