import React from 'react';
import { Link } from '@reach/router';
import ImageGallery from "../components/ImageGallery";
import Footer from '../components/footer';


export default () => (
<div>
  <section className='jumbotron breadcumb' style={{backgroundImage: `url(${'./img/porto.jpg'})`}}>
    <div className='mainbreadcumb'>
      <div className='container-fluid'>
        <div className='row m-10-hor'>
          <div className='col-md-6'>
            <h1 style={{textShadow: '0 0 15px rgba(0, 0, 0, 1)'}}>Portofolio</h1>
          </div>
          <div className='col-md-6'>
            <div className='list' style={{textShadow: '0 0 20px rgba(0, 0, 0, 1), 0 0 20px rgba(0, 0, 0, 1), 0 0 20px rgba(0, 0, 0, 1)'}}>
              <Link className='link' to="/home">Home</Link>
              <span className='dash'>/</span>
              <span>Portofolio</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className='container-fluid black' style={{paddingBottom: '0px'}}>
        <div className='row m-10-hor'>

        <div className='col-md-5'>
            <div className='heading'>
              Explore<span className='br'></span> Our<span className='br'></span> Projects
            </div>
          </div>

          <div className='col-md-7'>
            <div className='content'>
            At Lux Power Pty Ltd, we take pride in our workmanship and attention to detail. Browse through our gallery of completed projects to see the quality and craftsmanship we deliver to our clients. From residential renovations to commercial installations, our team has the expertise to handle projects of any scale.
            </div>
            <div className='content'>
            With a focus on innovation and customer satisfaction, we strive to exceed expectations with every job we undertake. Let our portfolio speak for itself and discover why Lux Power Pty Ltd is the trusted choice for electrical services in Sydney and beyond.
            </div>
          </div>

        </div>
  </section>

  <section className='container-fluid black_more'>
    <div className='row m-10-hor'>
      <div className='col-12'>
        <div className='AppContainer'>
          <ImageGallery />
        </div>
      </div>
    
    </div>
  </section>

  <Footer />
</div>

);
