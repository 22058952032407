import React from 'react';
import { Link } from '@reach/router';

export default () => (
  <section className='container-fluid p-md-0'>
        <div className='row'>

          <div className='col-md-6'>
            <img src='./img/mocks.jpg' alt='imgbanner' className='w-100 border-radius'/>
          </div>
          <div className='col-md-6 centered'>
            <div>
              <div className="subheading mt-md-0 mt-5">
              Connect with Us Today
              </div>
              <div className="heading">
                Your Trusted Electrical Partner
              </div>
              <p>
              Count on Lux Power Pty Ltd for reliable and professional electrical services.
                <span className='br'></span>With a commitment to excellence and customer satisfaction, we're here to meet all your electrical needs.
                <span className='br'></span>Contact us today and experience the Lux Power difference.
              </p>
              <Link className='btn' to="/contact">
                <span className="shine"></span>
                <span>Contact Us</span>
              </Link>
            </div>
          </div>
          
        </div>
    </section>
);
