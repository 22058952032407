import React from 'react';
import { Link } from '@reach/router';
import Testimony from '../components/carouseltestimony';
import Footer from '../components/footer';
import { Player, BigPlayButton, LoadingSpinner, ControlBar } from 'video-react';
import "../../../node_modules/video-react/dist/video-react.css";

export default () => {
  return (<div className='wraperitem'>
  <section className='jumbotron breadcumb' style={{backgroundImage: `url(${'./img/service.jpg'})`}}>
    <div className='mainbreadcumb'>
      <div className='container-fluid'>
        <div className='row m-10-hor'>
          <div className='col-md-6'>
            <h1 style={{textShadow: '0 0 15px rgba(0, 0, 0, 1)'}}>Service</h1>
          </div>
          <div className='col-md-6'>
            <div className='list' style={{textShadow: '0 0 20px rgba(0, 0, 0, 1), 0 0 20px rgba(0, 0, 0, 1), 0 0 20px rgba(0, 0, 0, 1)'}}>
              <Link className='link' to="/home">Home</Link>
              <span className='dash'>/</span>
              <span>Service</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className='container-fluid' id="service">
        <div className='row m-10-hor'>
          <div className='col-md'>
            <div className='left'>
                {/* <img
                    src="./img/service.jpg"
                    alt="imgservice"
                  /> */}
                  <Player
                    src="./img/service.mov"
                    className='mainimg'
                    autoPlay={true}
                    muted={true}
                  >
                    <BigPlayButton position="center" />
                    <LoadingSpinner />
                    <ControlBar autoHide={true} autoHideTime={100} disableCompletely={true} />
                  </Player>
              <div className='content'>
                <div className='title'>
                Professional <span className='color'>Electrical Services</span>
                </div>
                <div className='desc'>
                  <p>Our dedicated team, proficient in cutting-edge electrical installations, maintenance, and comprehensive fault detection, caters especially to luxury homes with unmatched precision. Leveraging the finest materials, we stand by our work with a lifetime warranty on workmanship, alongside material warranties ranging up to seven years. Our commitment to excellence ensures every project, from service and maintenance to new builds, meets the highest standards of quality and safety.</p>
                  </div>
                <div className='title'>
                Advanced <span className='color'>Air Conditioning</span>
                </div>
                <div className='desc'>
                  <p>We specialize in providing top-tier air conditioning services, encompassing installation, maintenance, and repair with a focus on efficiency, reliability, and comfort. Our approach integrates the latest technology and highest-quality materials to deliver sustainable and energy-efficient air conditioning solutions. Tailored to both residential and commercial spaces, our services are designed to ensure optimal performance and longevity, backed by our comprehensive warranties.</p>
                  </div>
                <div className='title'>
                Secure & Reliable <span className='color'>Alarm Systems</span>
                </div>
                <div className='desc'>
                  <p>Our security and alarm system services are designed to offer peace of mind through advanced, reliable solutions tailored to your specific needs. From sophisticated alarm systems to comprehensive security installations, our team ensures your residential, commercial, or industrial property is protected with the latest technology. Our expertise in fault finding and addressing defects guarantees enhanced safety and security for your premises.</p>
                  </div>
                <div className='title'>
                Nationwide <span className='color'>Excellence</span>
                </div>
                <div className='desc'>
                  <p>As we ambitiously expand our reach across Australia, our core values of professionalism, quality, and customer satisfaction guide us. Our vision to be the top electrical services company in Australia is fueled by our commitment to offer exceptional services without the burden of unrealistic costs. Join us on our journey to set new standards in the electrical services industry, where customer satisfaction remains at the heart of everything we do.</p>
                  </div>
              </div>
            </div>
          </div>
          {/* <div className='col-md-4'>
            <div className='right'>
              <ul className='services-list'>
                <li>
                  <Link to="/works">All Works</Link>
                </li>
                <li>
                  <Link className='active' to="/service">Modern Design</Link>
                </li>
                <li>
                  <Link to="/service1">Interior</Link>
                </li>
                <li>
                  <Link to="/service2">Architecture</Link>
                </li>
                <li>
                  <Link to="/service3">Exterior</Link>
                </li>
              </ul>
              <div className='bannerservice'>
                <div className='imgbg'>
                  <img
                    src="./img/bg-widget.jpg"
                    alt="imgservice"
                  />
                </div>
                <div className='contb'>
                  <div className='headingb'>
                    Get A quote 
                  </div>
                  <div className='descb'>
                    With Experts Advice 
                  </div>
                  <div className="btn" onClick={()=> window.open("/#", "_self")}>
                    <span className="shine"></span>
                    <span>Download here</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
  </section>

  <Testimony />
  <Footer />
</div>

);
}
