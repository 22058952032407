import React from 'react';
import { Link } from '@reach/router';

export default () => (
  <footer className='container-fluid black_more'>
  <div className='bg-footer'>
    <img src='./img/footer-img.jpg' alt='bg-footer'/>
  </div>
    <div className='row m-10-hor'>
      <div className='col-md-4'>
        <div className='footer-col'>
          {/* <div className='heading'>
            <h2 className='font-weight-bold '>About Us</h2>
          </div> */}
          <div className='content'>
             <p>Celebrating excellence in electrical services, Lux Power Pty Ltd delivers innovation, quality, and satisfaction to every project.</p>
          </div>
          <div className='link-call' onClick={()=> window.open("mailto:luxpowerservices@gmail.com", "_self")}>Email at luxpowerservices@gmail.com</div>
          <div className='link-call' onClick={()=> window.open("tel:+61470202876", "_self")}>Call us at +61470202876</div>
        </div>
      </div>
      <div className='col-md-2'>
        <div className='footer-col'>
          {/* <div className='heading'>
            Usefull link
          </div> */}
          <div className='content'>
             {/* <div className='link'>Frequently Asked</div> */}
             {/* <div className='link'>Terms & Conditions</div> */}
             {/* <div className='link'>Help Center</div> */}
             <div className='link'>
                <Link to="/contact" style={{ textDecoration: 'none', color: 'inherit'}}>Contact Us</Link>
              </div>
          </div>
        </div>
      </div>
      {/* <div className='col-md-2'>
        <div className='footer-col'>
          <div className='heading'>
            Features
          </div>
          <div className='content'>
            <div className='link'>Career</div>
            <div className='link'>Brand Identity</div>
            <div className='link'>Investment</div>
            <div className='link'>Agency Patner</div>
          </div>
        </div>
      </div> */}
      <div className='col-md-4'>
        <div className='footer-col'>
          <div className='heading'>
            Follow Us
          </div>
          <div className='content'>
            <p>Stay Connected with Lux Power</p>
            {/* <div className='socialicon'>
            <span className="shine"></span>
              <i className="fa fa-facebook-f"></i>
            </div>
            <div className='socialicon'>
            <span className="shine"></span>
              <i className="fa fa-linkedin"></i>
            </div>
            <div className='socialicon'>
            <span className="shine"></span>
              <i className="fa fa-twitter"></i>
            </div> */}
            <div className='socialicon'>
            <span className="shine"></span>
              <a href='https://instagram.com/lux_power_pty_ltd'>
                <i className="fa  fa-instagram"></i>
              </a>
            </div>
            <div className='socialicon'>
            <span className="shine"></span>
              <a href='https://t.snapchat.com/sjPthB6l'>
                <i className="fa  fa-snapchat"></i>
              </a>
            </div>
            <div className='socialicon'>
            <span className="shine"></span>
              <a href='https://www.facebook.com/profile.php?id=100077024960915&mibextid=PlNXYD'>
                <i className="fa  fa-facebook"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <div className='subfooter'>
      <div className='row m-10-hor'>
        <div className='col-md-6'>
          <div className='content'>© Copyrights 2020 <span className='font-weight-bold'>Artha</span> All rights reserved.</div>
        </div>
      </div>
    </div> */}
  </footer>
);
