import React from 'react';
import Slider from '../components/sliderhome';
import Bannercontact from '../components/bannercontact';
import Testimony from '../components/carouseltestimony';
import Footer from '../components/footer';


export default () => (
  <div>
      <section className="jumbotron jumbomain">
          <Slider />
          {/* <div className="icon-scroll-wraper">
            <div className="icon-scroll">
              <div className="icon-scroll-screen"></div>
            </div>
          </div> */}
      </section>

      <section className='container-fluid p-0'>
        <div className='row'>

          <div className='col-md-4 p-0'>
            <div className='features'>
              <div className='bg'>
                <img
                    src="./img/bg-serv-1.jpg"
                    alt="bg-features"
                  />
              </div>
              <div className='content'>
                <br />
                <br />
                <br />
                <div className='heading'>
                Elevate Your Home
                </div>
                <br/>
                <div className='con-text'>
                <br />
                <br />
                <br />
                </div>
                {/* <br/>
                <div className='link'>
                  <span className="shine"></span>
                  Read More
                </div> */}
              </div>
            </div>
          </div>

          <div className='col-md-4 p-0'>
            <div className='features'>
              <div className='bg'>
                <img
                    src="./img/bg-serv-2.jpg"
                    alt="bg-features"
                  />
              </div>
              <div className='content'>
                <br />
                <br />
                <br />
                <div className='heading'>
                Elegant Decor
                </div>
                <br/>
                <div className='con-text'>
                <br />
                <br />
                <br />
                </div>
                {/* <br/>
                <div className='link'>
                  <span className="shine"></span>
                  Read More
                </div> */}
              </div>
            </div>
          </div>

          <div className='col-md-4 p-0'>
            <div className='features'>
              <div className='bg'>
                <img
                    src="./img/bg-serv-3.jpg"
                    alt="bg-features"
                  />
              </div>
              <div className='content'>
                <br />
                <br />
                <br />
                <div className='heading'>
                Exquisite Custom Designs
                </div>
                <br/>
                <div className='con-text'>
                <br />
                <br />
                <br />

                </div>
                {/* <br/>
                <div className='link'>
                  <span className="shine"></span>
                  Read More
                </div> */}
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className='container-fluid black pb-0'>
        <div className='row m-10-hor'>

          <div className='col-md-5'>
            <div className='heading'>
              why <span className='br'></span>choose<span className='br'></span> us?
            </div>
          </div>

          <div className='col-md-7'>
            <div className='content'>
            At Lux Power Pty Ltd, we pride ourselves on delivering excellence in every aspect of our service. With a commitment to professionalism, quality, and customer satisfaction, we stand as a trusted partner for all your electrical needs. From innovative solutions to meticulous attention to detail, we strive to exceed expectations and earn your trust with every project.
            </div>
          </div>

        </div>
    </section>

    <section className='container-fluid pt-0 black'>
        <div className='row m-10-hor'>
         <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              01.
            </div>
            <div className='heading text-uppercase'>
            Expert Team
            </div>
            <div className='content'>
            Our team of skilled professionals is equipped with extensive experience and expertise to handle projects of any scale with precision and efficiency. Count on us for reliable service backed by industry knowledge.
            </div>
          </div>
         </div>

         <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              02.
            </div>
            <div className='heading text-uppercase'>
            Quality Materials
            </div>
            <div className='content'>
            We source and utilize only the highest quality materials, ensuring durability, reliability, and long-term performance for every installation. Trust in our commitment to excellence in craftsmanship.
            </div>
          </div>
         </div>

         <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              03.
            </div>
            <div className='heading text-uppercase'>
            Customer-Centric Approach
            </div>
            <div className='content'>
            At Lux Power Pty Ltd, customer satisfaction is at the forefront of everything we do. We listen to your needs, communicate transparently, and tailor our solutions to exceed your expectations.
            </div>
          </div>
         </div>

         <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              04.
            </div>
            <div className='heading text-uppercase'>
            Timely Project Completion
            </div>
            <div className='content'>
            We understand the importance of deadlines. With efficient project management and meticulous planning, we ensure timely completion of every project without compromising on quality.
            </div>
          </div>
         </div>

         <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              05.
            </div>
            <div className='heading text-uppercase'>
            Innovative Solutions
            </div>
            <div className='content'>
            Stay ahead of the curve with our innovative solutions that blend cutting-edge technology with functional design. Experience the latest advancements in electrical services for your home or business.
            </div>
          </div>
         </div>

         <div className='col-md-4 mt-5'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              06.
            </div>
            <div className='heading text-uppercase'>
            Lifetime Workmanship Warranty
            </div>
            <div className='content'>
            Rest assured with our lifetime workmanship warranty, providing you with peace of mind and confidence in the quality of our work. Experience reliability that lasts a lifetime with Lux Power Pty Ltd.
            </div>
          </div>
         </div>

        </div>
    </section>

    {/* <section className='container-fluid black_more'>
        <div className='row m-10-hor'>
          <div className='col-12 text-center'>
            <div className='subheading'>Our team</div>
            <div className='heading'>About Our Team</div>
            <div className='row'>
              <div className='col-md-8 mx-auto'>
                <p className='content'>
                  Curabitur mollis bibendum luctus. 
                  Duis suscipit vitae dui sed suscipit. Vestibulum auctor 
                  nunc vitae diam eleifend, in maximus metus sollicitudin. 
                  Quisque vitae sodales lectus. Nam p
                  orttitor justo sed mi finibus, 
                  vel tristique risus faucibus. 
                </p>
              </div>
            </div>
          </div>
          <Carouselteam />
        </div>
    </section> */}

    <Bannercontact/>
    {/* <Pricing /> */}
    <Testimony />
    <Footer />

  </div>
);
