export default [
  {
    src: "/img/gallery/1.jpg",
    alt: "",
    caption: "",
    width: 532,
    height: 500
  },
  {
    src:
      "/img/gallery/2.jpg",
    alt: "",
    caption: "",
    width: 532,
    height: 709
  },
  {
    src:
      "/img/gallery/3.jpg",
    alt: "",
    caption: "",
    width: 532,
    height: 517
  },
  {
    src:
      "/img/gallery/4.jpg",
    alt: "",
    caption: "",
    width: 725,
    height: 532
  },
  {
    src: "/img/gallery/5.jpg",
    alt: "",
    caption: "",
    width: 532,
    height: 619
  },
  {
    src:
      "/img/gallery/6.jpg",
    alt: "",
    caption: "",
    width: 532,
    height: 596
  },
  {
    src:
      "/img/gallery/7.jpg",
    alt: "",
    caption: "",
    width: 532,
    height: 709
  },
  {
    src:
      "/img/gallery/8.jpg",
    alt: "",
    caption: "",
    width: 532,
    height: 709
  },
  {
    src:
      "/img/gallery/9.jpg",
    alt: "",
    caption: "",
    width: 532,
    height: 619
  }
];
